import { appProvider } from '@/app-providers'
import { FixedSwapContractPurchase, MarketKeyInfo, UserConstraints } from '@/blockchainHandlers/ido-contract-interface'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { UNLIMITED_ALLOCATION, Zero } from '@/constants'
import { bnHelper } from '@/helpers/bignumber-helper'
import { numberHelper } from '@/helpers/number.hepler'
import { ApplyModel } from '@/models/apply-model'
import { apiService } from '@/services/api-service'
import { walletStore } from '@/stores/wallet-store'
import { isEmpty } from 'lodash'
import { action, autorun, computed, IReactionDisposer, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { formatDuration } from '../business/swap-contract.business'
import { PoolStore } from '../stores/pool-store'
import { poolsStore } from '../stores/pools-store'

export class PurchasedItemViewModel {
  @observable loading = false
  @observable wasFinalized = false

  constructor(
    public purchase: FixedSwapContractPurchase,
    public poolStore: PoolStore,
    private claimed: () => void,
    public keyId = 0
  ) {
    this.wasFinalized = purchase.wasFinalized
  }

  @asyncAction *claimToken() {
    this.loading = true
    const contract = this.poolStore?.contract
    try {
      if (!contract) throw 'Contract is undefined'
      if (!walletStore.account) throw 'wallet is undefined'
      yield contract.redeemTokens(this.keyId, this.purchase._id, walletStore.account)
      snackController.success('Withdraw successfully')
      this.claimed()
      this.wasFinalized = true
    } catch (error: any) {
      console.error(error)
      snackController.error(error.message || error.msg)
    } finally {
      this.loading = false
    }
  }

  @computed get canRedeemTokens() {
    let ok = this.poolStore?.canRedeemTokens && !!this.purchase.validAfterDate
    ok = ok && appProvider.currentTime.isAfter(moment(this.purchase.validAfterDate))
    return ok
  }
}

export class IdoPoolDetailViewModel {
  @observable poolid = ''
  @observable poolStore?: PoolStore
  @observable keyInfos: MarketKeyInfo[] = []
  @observable loadingTable = false
  @observable isWhitelisted = false
  @observable accountLoading = false
  @observable userTierId: number | null = null
  @observable apply: ApplyModel | undefined
  @observable investor: any

  @observable investSuccessDialog = false

  @observable selectedMarketKey?: MarketKeyInfo = undefined

  _disposers: IReactionDisposer[] = []

  constructor() {
    this._disposers = [
      autorun(() => {
        if (this.poolStore && walletStore.chainId === this.chainId && walletStore.account) {
          this.loadUserInfo()
        }
      }),
    ]
  }

  @computed get purchases() {
    const poolStore = this.poolStore
    if (!poolStore) return []
    return (
      this.selectedMarketKey?.purchases.map(
        (p) => new PurchasedItemViewModel(p, poolStore, () => this.loadUserInfo(true), this.selectedMarketKey?.id || 0)
      ) || []
    )
  }

  @computed get disableTransfer() {
    return this.poolStore?.pool?.data?.disableTransfer
  }

  @asyncAction *getInvestor(walletAddress: string) {
    const users = yield apiService.users.find({
      username: walletAddress,
    })
    const user = users[0]
    if (user) return user.investor
    return null
  }

  @asyncAction *loadUserInfo(force = false) {
    if (this.accountLoading) return
    const contract = this.poolStore?.contract
    const account = walletStore.account
    this.accountLoading = true
    try {
      if (contract && this.poolStore && account && walletStore.chainId === this.chainId) {
        yield this.poolStore.loadData()
        const { isWhitelisted, userTier, keyId } = (yield contract.fetchUserConstraints(
          walletStore.account,
          force
        )) as UserConstraints
        this.investor = yield this.getInvestor(account)
        if (this.investor) {
          const getApply = yield apiService.applies.find(
            {
              pool: this.poolId,
              investor: this.investor?.id,
              walletAddress: account,
            },
            { _limit: 1 }
          ) || []
          this.apply = getApply[0]
        }

        this.userTierId = userTier.id
        // console.log('purchases', purchases)
        this.contributedUserKeyId = keyId || 0
        this.isWhitelisted = isWhitelisted
        // this.purchases = purchases.map((p) => new PurchasedItemViewModel(p, contract, this))
        // if (walletStore.chainType !== 'sol') {
        this.accountLoading = false
        this.keyInfos = yield contract.fetchUserMarketKeyInfos(account, force)
        // }
      } else {
        this.contributedUserKeyId = 0
        this.keyInfos = []
      }
    } finally {
      this.accountLoading = false
    }
  }

  @observable contributedUserKeyId = 0

  @computed get userContributedKey() {
    return this.keyInfos.find((x) => x.id === this.contributedUserKeyId)
  }

  @asyncAction *loadPool(unicodeName: string) {
    try {
      this.poolStore = yield poolsStore.getPool(unicodeName)
    } catch (e) {
      snackController.commonError(e)
    }
    this.loadUserInfo()
  }

  @action.bound onResearchProject() {
    if (!this.researchProject) return
    window.open(this.researchProject, '_blank')
  }

  @action.bound openWebsite() {
    if (this.web) {
      window.open(this.web, '_blank')
    } else {
      appProvider.router.push('/comingSoon')
    }
  }

  @action.bound setInvestSuccessDialog(val) {
    this.investSuccessDialog = val
  }
  @action.bound setSelectedMarketKey(val) {
    this.selectedMarketKey = val
  }

  @computed get showAllocationDialog() {
    return !!this.selectedMarketKey
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @computed get tokenVestingSchedule() {
    const res = !isEmpty(this.poolStore?.redeemConfigs)
      ? this.poolStore?.redeemConfigs
      : this.pool?.data?.redeemConfigs?.map((item) => ({ ...item, date: moment(item.date) })) || []
    return res
  }

  @computed get tokenVestingMessage() {
    return this.pool?.data?.redeemConfigsEmpty || 'No data availabe'
  }

  @computed get chain() {
    return this.poolStore?.pool?.chain
  }
  @computed get chainId() {
    return this.poolStore?.pool?.chainId
  }
  @computed get pool() {
    return this.poolStore?.pool
  }
  @computed get poolId() {
    return this.poolStore?.pool.id
  }
  @computed get unicodeName() {
    return this.poolStore?.pool.unicodeName
  }
  @computed get minAllocation() {
    return this.pool?.minAllocation ?? 'No minimum'
  }
  @computed get maxAllocation() {
    return this.pool?.maxAllocation ?? 'No maximum'
  }
  @computed get accessType() {
    return this.pool?.accessType
  }
  @computed get totalSupply() {
    return this.pool?.totalSupply ? numberHelper.formatNumber(this.pool?.totalSupply, 2) : 'TBA'
  }
  @computed get tokenDistribution() {
    return this.pool?.startDate
  }
  @computed get publishedText() {
    const poolState = this.poolStore?.poolState
    if (!poolState) return ''
    const { started, startDuration } = poolState
    return started ? `Published ${formatDuration(startDuration)} ago` : ''
  }

  @computed get isExtendKyc() {
    return (
      !this.isInWhitelisted &&
      this.isBeforeExtendKyc &&
      this.apply &&
      this.apply?.status === 'whitelisted' &&
      this.apply.isCommunityRound &&
      !this.apply.isTierRound
    )
  }
  @computed get isUserVerifiedKyc() {
    return this.investor?.status === 'kyc-verified'
  }
  @computed get isBeforeExtendKyc() {
    return this.extendKycEndDate && this.poolState?.currentTime.isBefore(this.extendKycEndDate)
  }

  @computed get closesText() {
    const poolState = this.poolStore?.poolState
    if (!poolState) return ''
    const { ended, endDuration } = poolState
    return ended ? 'Ended' : `Ended in ${formatDuration(endDuration)}`
  }
  @computed get poolName() {
    return this.pool?.name || ''
  }
  @computed get tokenName() {
    return this.pool?.tokenName || ''
  }
  @computed get tokenAddress() {
    return this.pool?.tokenAddress
  }
  @computed get contractAddress() {
    return this.pool?.address || ''
  }
  @computed get tradeValue() {
    return this.poolStore?.tradeValue || ''
  }
  @computed get poolState() {
    return this.poolStore?.poolState
  }
  @computed get purchasedTokens() {
    return this.poolStore?.purchasedTokens
  }
  @computed get totalTokens() {
    return this.poolStore?.totalTokens
  }
  @computed get progress() {
    return this.poolStore?.progress
  }
  @computed get participants() {
    return this.poolStore?.participants
  }
  @computed get discount() {
    return this.pool?.data?.discount
  }
  @computed get description() {
    return this.pool?.description
  }

  @computed get shortDescription() {
    return this.pool?.data?.shortDescription
  }

  @computed get tradeToken() {
    return this.poolStore?.tradeToken || ''
  }
  @computed get ratio() {
    return this.pool?.ratio
  }
  @computed get marketPrice() {
    return this.poolStore?.marketPrice
  }
  @computed get allowSwap() {
    return this.poolStore && this.poolStore.allowSwap && !this.accountLoading
  }
  @computed get researchLink() {
    return this.pool?.data?.researchLink || ''
  }
  @computed get facebook() {
    return this.poolStore?.facebook
  }
  @computed get reddit() {
    return this.poolStore?.reddit
  }
  @computed get twitch() {
    return this.poolStore?.twitch
  }
  @computed get youtube() {
    return this.poolStore?.youtube
  }
  @computed get tiktok() {
    return this.poolStore?.tiktok
  }
  @computed get instagram() {
    return this.poolStore?.instagram
  }
  @computed get linkedIn() {
    return this.poolStore?.linkedIn
  }
  @computed get blog() {
    return this.poolStore?.blog
  }

  @computed get isSaleStarted() {
    return this.poolStore?.poolState?.started
  }
  @computed get isSaleEnded() {
    return this.poolStore?.poolState?.ended
  }

  @computed get descriptionWordIframe() {
    return this.pool?.data?.descriptionWordIframe || ''
  }

  @computed get isUpcomingProject() {
    return !this.pool?.address && moment(this.pool?.startDate).isAfter(moment())
  }
  @computed get isInWhitelisted() {
    const isWhitelisted = this.isWhitelisted
    const hasWhitelist = this.poolStore?.hasWhitelist
    return this.contractAddress && (!hasWhitelist || isWhitelisted)
  }
  @computed get claimAirdrop() {
    return this.pool?.data?.claimType === 'airdrop'
  }
  @computed get logoImage() {
    return this.pool?.logoUrl || this.pool?.file
  }
  @computed get hasSocialInformations() {
    const { medium, web, telegram, twitter, discord } = this.pool?.data || {}
    return medium || web || telegram || twitter || discord
  }
  @computed get medium() {
    return this.pool?.data?.medium
  }
  @computed get web() {
    return this.pool?.data?.web
  }
  @computed get researchProject() {
    return this.pool?.data?.researchProject
  }
  @computed get twitter() {
    return this.pool?.data?.twitter
  }
  @computed get telegram() {
    return this.pool?.data?.telegram
  }
  @computed get discord() {
    return this.pool?.data?.discord
  }
  @computed get totalRaiseUsd() {
    return this.poolStore?.totalRaiseUsd
  }
  @computed get isMultiToken() {
    return this.pool?.data?.isMultiToken
  }
  @computed get tokenList() {
    return this.pool?.data?.tokenList || []
  }

  @computed get forceWhitelist() {
    return this.pool?.data?.forceWhitelist
  }
  @computed get stakedTierPools() {
    if (!this.poolStore) return []
    return this.poolStore.tierPools
      .filter((t) => t.id > 0)
      .map((tier) => {
        return {
          ...tier,
          canSwap: tier.canSwap && this.isInWhitelisted && tier.id === this.userTierId,
          canInvest: this.isInWhitelisted && tier.id === this.userTierId,
          isUnlimitedAllocation: bnHelper.gte(tier.maxCost || Zero, UNLIMITED_ALLOCATION),
        }
      })
  }

  @computed get isFilledStakedTierPools() {
    const sum = (this.stakedTierPools || []).reduce((pre, cur) => pre + cur?.progress || 0, 0)
    return sum == 400 || this.forceFilledPool
  }
  @computed get isFilledCommunityPool() {
    const sum = (this.communityTierPool || []).reduce((pre, cur) => pre + cur?.progress || 0, 0)
    return sum == 100 || this.forceFilledPool
  }

  @computed get getUserPoolText() {
    if (this.userTierId != null && this.userTierId === 0) return 'Waggle Community Pool'
    else return `Waggle Tier Pool -  ${this.getTierText(this.userTierId)}`
  }
  @computed get enablePagination() {
    return this.tokenVestingSchedule && this.tokenVestingSchedule.length > 4
  }
  @computed get communityTierPool() {
    if (!this.poolStore) return []
    return this.poolStore.tierPools
      .filter((t) => t.id === 0)
      .map((tier) => {
        return {
          ...tier,
          isCommunityRound: true,
          canSwap: tier.canSwap && this.isInWhitelisted && tier.id === this.userTierId,
          canInvest: this.isInWhitelisted && tier.id === this.userTierId,
          isUnlimitedAllocation: bnHelper.gte(tier.maxCost || Zero, UNLIMITED_ALLOCATION),
        }
      })
  }

  @computed get shortTokenAddress() {
    return (
      (this.pool?.tokenAddress &&
        this.pool.tokenAddress.substr(0, 10) +
          '...' +
          this.pool.tokenAddress.substr(this.pool.tokenAddress.length - 9)) ||
      'TBA'
    )
  }

  @computed get tierCountdownSeconds() {
    return this.stakedTierPools[0]?.countdownSeconds || 0
  }
  @computed get tierCountdownText() {
    return this.stakedTierPools[0]?.countdownText || 0
  }
  @computed get tierCountdownMinutes() {
    return this.stakedTierPools[0]?.countdownMinutes || 0
  }
  @computed get tierCountdownHours() {
    return this.stakedTierPools[0]?.countdownHours || 0
  }
  @computed get tierCountdownDays() {
    return this.stakedTierPools[0]?.countdownDays || 0
  }
  @computed get tierCountdown() {
    return {
      countdownText: this.tierCountdownText,
      countdownDays: this.tierCountdownDays,
      countdownHours: this.tierCountdownHours,
      countdownMinutes: this.tierCountdownMinutes,
      countdownSeconds: this.tierCountdownSeconds,
    }
  }

  @computed get communityCountdownSeconds() {
    return this.communityTierPool[0]?.countdownSeconds || 0
  }
  @computed get communityCountdownText() {
    return this.communityTierPool[0]?.countdownText || 0
  }
  @computed get communityCountdownMinutes() {
    return this.communityTierPool[0]?.countdownMinutes || 0
  }
  @computed get communityCountdownHours() {
    return this.communityTierPool[0]?.countdownHours || 0
  }
  @computed get communityCountdownDays() {
    return this.communityTierPool[0]?.countdownDays || 0
  }
  @computed get communityCountdown() {
    return {
      countdownText: this.communityCountdownText,
      countdownDays: this.communityCountdownDays,
      countdownHours: this.communityCountdownHours,
      countdownMinutes: this.communityCountdownMinutes,
      countdownSeconds: this.communityCountdownSeconds,
    }
  }

  @computed get isWhitelistStarted() {
    return this.poolState?.isWhitelistStarted
  }
  @computed get isWhitelistEnd() {
    return this.poolState?.isWhitelistEnd
  }
  @computed get isWhitelistPublic() {
    return this.poolState?.isWhitelistPublic
  }
  @computed get extendKycEndDate() {
    return this.poolStore?.extendKycEndDate
  }
  @computed get isKycEnd() {
    return this.poolState?.isKycEnd
  }

  @computed get currentPoolState() {
    if (!this.isWhitelistStarted) return 'WHITELIST IS UPCOMING'
    else if (!this.isWhitelistEnd) return 'WHITELIST IS OPEN'
    else if (!this.isKycEnd) return 'WHITELIST CLOSED'
    else if (!this.isSaleStarted) return 'THE SALE IS UPCOMING'
    else if (!this.isSaleEnded) return 'THE SALE IS OPEN'
    else if (this.isSaleEnded) return 'THE SALE ENDED'
    else return ''
  }
  @computed get isRedPoolState() {
    if ((this.isWhitelistEnd && !this.isKycEnd) || this.isSaleEnded) return true
  }
  @computed get getCountdownText() {
    return this.poolStore?.poolState?.countdownText || ''
  }
  @computed get getCountdownTargetDate() {
    return this.poolStore?.poolState?.countdownTargetDate || ''
  }

  @computed get isTBACountDown() {
    return this.poolStore?.poolState?.isTBACountDown || false
  }
  @computed get countdownSeconds() {
    return this.poolStore?.poolState?.countdownSeconds || 0
  }
  @computed get countdownMinutes() {
    return this.poolStore?.poolState?.countdownMinutes || 0
  }
  @computed get countdownHours() {
    return this.poolStore?.poolState?.countdownHours || 0
  }
  @computed get countdownDays() {
    return this.poolStore?.poolState?.countdownDays || 0
  }
  @computed get forceFilledPool() {
    return this.poolStore?.forceFilledPool
  }
  @computed get isNotDescriptionMarkdown() {
    return this.poolStore?.isNotDescriptionMarkdown
  }
  private getTierText(tierId) {
    switch (tierId) {
      case 1:
        return 'MINI BEE'
      case 2:
        return 'WORKER BEE'
      case 3:
        return 'DRONE BEE'
      case 4:
        return 'QUEEN BEE'
      default:
        return 'Community'
    }
  }
}
