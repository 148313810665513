import { FixedPoolModel } from '@/models/fixed-pool-model'
import { apiService } from '@/services/api-service'
import { sortBy } from 'lodash-es'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { PoolStore } from './pool-store'

export class PoolsStore {
  @observable cachedPoolStore: { [id: string]: PoolStore } = {}
  @observable allPools: PoolStore[] = []

  //#region ACTIONS
  _fetchedAll = false;
  @asyncAction *fetchPoolsIfNeed() {
    if (this._fetchedAll) return this.allPools
    else yield this.fetchPools()
    return this.allPools
  }

  @asyncAction *fetchPools() {
    const pools: FixedPoolModel[] = yield apiService.fixedPool.find({ _sort: 'index:DESC' })
    this.allPools = pools.map((p) => {
      let pool = this.cachedPoolStore[p.id as any]
      if (pool) {
        pool.changeModel(p)
      } else {
        pool = new PoolStore(p)
        this.cachedPoolStore[p.id as any] = pool
      }
      return pool
    })
    this._fetchedAll = true
  }

  @asyncAction *getPool(unicodeName: string) {
    const res: FixedPoolModel[] = yield apiService.fixedPool.find({ unicodeName }, { _limit: 1 }) || []
    if (res.length <= 0 || !res[0]) return null
    const pool = res[0]
    let poolStore = this.cachedPoolStore[pool.id as any]
    if (poolStore) {
      poolStore.changeModel(pool)
    } else {
      poolStore = new PoolStore(pool)
      this.cachedPoolStore[pool.id as any] = poolStore
    }
    return poolStore
  }
  @asyncAction *findPool(unicodeName: string) {
    const res = yield apiService.fixedPool.find({ unicodeName }, { _limit: 1 })
    const pool = res[0]
    if (pool) {
      let poolStore = this.cachedPoolStore[pool.id as any]
      if (poolStore) {
        poolStore.changeModel(pool)
      } else {
        poolStore = new PoolStore(pool)
        this.cachedPoolStore[pool.id as any] = poolStore
      }
      return poolStore
    }
    return null
  }
  //#endregion

  @action.bound getStore(pool: FixedPoolModel) {
    let poolStore = this.cachedPoolStore[pool.id as any]
    if (poolStore) {
      poolStore.changeModel(pool)
    } else {
      poolStore = new PoolStore(pool)
      this.cachedPoolStore[pool.id as any] = poolStore
    }
    return poolStore
  }
  //#endregion

  //#region COMPUTEDS
  @computed get validPools() {
    // const chainId = walletStore.chainId // process.env.NODE_ENV === 'production' ? 56 : walletStore.chainId
    const valids = this.allPools.filter((p) => !p.pool?.data?.forceHide)
    return sortBy(valids, (x) => -(x.pool.index || 0))
  }
  @computed get cacedPoolByUnicodes() {
    return Object.values(this.cachedPoolStore).reduce((prev, cur) => {
      prev[cur.unicodeName] = cur
      return prev
    }, {} as { [id: string]: PoolStore })
  }
  //#endregion
}

export const poolsStore = new PoolsStore()
